import { ApplicationResponse } from '@features/applications/types/application-response.ts';
import { OtpDisclosureApproval } from '@features/wizard/components/start/otp-disclosure-approval.tsx';
import { IdentityStartFormValues } from '@features/wizard/components/start/start-step.tsx';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Select,
  TextField,
  Typography
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Controller, useFormContext } from 'react-hook-form';
import { usaStates } from 'typed-usa-states';

import PhoneNumberInputMask from '@/components/masking/phone-number-input-mask.tsx';
import SsnInputMask from '@/components/masking/ssn-input-mask.tsx';
import { validateSsn } from '@/components/validation/validate-ssn.ts';
import { getBirthdayRange, validateBirthdayRange } from '@/utils/dates.ts';

const PersonalIdentificationInputGroup = ({ application }: PersonalIdentificationInputGroupProps) => {
  const {
    ssn,
    birthdate,
    driversLicense,
    phone,
    isMobilePhone
  } = application;

  const form = useFormContext<IdentityStartFormValues>();
  const { control } = form;

  const { min: minBirthday, max: maxBirthday } = getBirthdayRange();

  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={12}>
        <Typography variant="body1" fontWeight={700}>Personal Identification</Typography>
      </Grid2>
      <Grid2 xs={12} sm={6}>
        <Controller
          control={control}
          name="ssn"
          defaultValue={ssn ?? ''}
          rules={{
            validate: validateSsn({
              label: 'Social Security Number',
              required: true
            })
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              label={'Social Security Number'}
              fullWidth
              error={Boolean(error)}
              helperText={error?.message}
              {...field}
              InputProps={{
                inputComponent: SsnInputMask as any
              }}
            />
          )}
        />
      </Grid2>
      <Grid2 xs={12} sm={6}>
        <Controller
          control={control}
          name="dob"
          defaultValue={birthdate ?? ''}
          rules={{
            required: 'Date of Birth is required.',
            validate: validateBirthdayRange
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              label="Date of Birth"
              placeholder="mm/dd/yyy"
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: minBirthday.toISODate(), max: maxBirthday.toISODate() }}
              type="date"
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </Grid2>
      <Grid2 xs={12} sm={6}>
        <Controller
          control={control}
          name="driversLicenseNumber"
          defaultValue={driversLicense?.number ?? ''}
          rules={{
            required: 'Driver\'s License Number is required.',
            maxLength: {
              value: 25,
              message: 'Driver\'s License Number cannot be more than 25 characters long.'
            }
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              label="Driver's License Number"
              placeholder="X123-456-78-900-0"
              error={!!error}
              helperText={error?.message}
              InputProps={{ inputProps: { maxLength: 25 } }}
            />
          )}
        />
      </Grid2>
      <Grid2 xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel>Driver's License State</InputLabel>
          <Controller
            control={control}
            name="driversLicenseState"
            defaultValue={driversLicense?.state ?? ''}
            rules={{
              required: 'Driver\'s License State is required.'
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <Select
                  native
                  variant={'filled'}
                  {...field}
                  error={!!error}
                >
                  <option value="">
                  </option>
                  {usaStates.map((state) => (
                    <option key={state.name} value={state.abbreviation}>
                      {state.name}
                    </option>
                  ))}
                </Select>
                <FormHelperText error>
                  {error?.message}
                </FormHelperText>
              </>
            )}
          />
        </FormControl>
      </Grid2>
      <Grid2 xs={12} sm={6}>
        <Controller
          control={control}
          name="phoneNumber"
          defaultValue={phone ?? ''}
          rules={{
            required: 'Phone Number is required.'
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              label={'Phone Number'}
              fullWidth
              error={Boolean(error)}
              helperText={error?.message}
              {...field}
              InputProps={{
                inputComponent: PhoneNumberInputMask as any
              }}
            />
          )}
        />
      </Grid2>
      <Grid2 xs={12}>
        <Controller
          control={control}
          name="isMobilePhone"
          defaultValue={isMobilePhone ?? ''}
          render={({ field }) => (
            <FormControlLabel
              label="This phone number is a mobile phone and can receive texts."
              control={ <Checkbox {...field} /> }
            />
          )}
        />
      </Grid2>
      <Box marginTop={1}>
        <OtpDisclosureApproval application={application}/>
      </Box>
    </Grid2>
  );
};

type PersonalIdentificationInputGroupProps = {
  application: ApplicationResponse;
}

export { PersonalIdentificationInputGroup };
