import { useUpdateBillingPreferences } from '@features/applications/api/update-billing-preferences.ts';
import {
  WithApplicationFromRoute,
  WithApplicationFromRouteProps
} from '@features/applications/components/with-application-from-route.tsx';
import { WorkableApplicationStepGuard } from '@features/applications/components/workable-application-step-guard.tsx';
import { mapBillingPreferencesFormToRequest } from '@features/wizard/billing-preferences.mappers.ts';
import { BillingPreferencesForm } from '@features/wizard/billing-preferences-form.ts';
import { FeeAmountCell } from '@features/wizard/components/billing-preferences/fee-amount-cell.tsx';
import {
  FeeDescriptionCell,
  FeeDescriptionRef
} from '@features/wizard/components/billing-preferences/fee-description-cell.tsx';
import { ServiceAvailabilityAlert } from '@features/wizard/components/service-availability-alert.tsx';
import { BillingPreference, FeeType, formatBillingPreference } from '@features/wizard/types/types.ts';
import useWizardRouter from '@features/wizard/wizard-router/use-wizard-router.ts';
import { WIZARD_STEP } from '@features/wizard/wizard-router/wizard-router.constants.ts';
import {
  Alert,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useId } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';

import AddressView from '@/components/address-view.tsx';
import useFormSubmitHandler from '@/core/forms/use-form-submit-handler.ts';
import SubmitButton from '@/core/ui/components/submit-button.tsx';
import { formatCurrency } from '@/utils/currency.ts';

const BillingPreferencesStepContainer = ({ application }: BillingPreferencesStepContainerProps) => {
  const { getStepRoute } = useWizardRouter({ applicationId: application.id });
  const fieldIdPrefix = useId();
  const idPrefix = useId();
  const form = useForm<BillingPreferencesForm>();
  const {
    control,
    handleSubmit,
  } = form;
  const { handleError } = useFormSubmitHandler({ form });

  const {
    mutate: updateBillingPreferences,
    isPending: updateBillingPreferencesIsPending,
    isSuccess: updateBillingPreferencesSuccess,
  } = useUpdateBillingPreferences();

  const membershipDescriptionId = `${idPrefix}-membershipDescription`;
  const depositDescriptionId = `${idPrefix}-depositDescription`;

  const FEE_DESCRIPTION_REF: FeeDescriptionRef = {
    [FeeType.MEMBERSHIP]: membershipDescriptionId,
    [FeeType.DEPOSIT]: depositDescriptionId
  };

  const {
    fees,
    serviceLocation: { services, serviceAddress },
    isPrepaidAvailable,
    prepaidMinimumToStart
  } = application;

  const applicableFees = fees?.filter(fee => fee.isApplicable) ?? [];
  const availableServices = services?.filter(service => service.status === 'residential');

  const standardTotal = applicableFees
    .reduce((sum, fee) => {
      sum += fee.standardAmount ?? 0;
      return sum;
    }, 0);

  const prepaidTotal = applicableFees
    .reduce((sum, fee) => {
      sum += fee.prepaidAmount ?? 0;
      return sum;
    }, 0);

  const onSubmit: SubmitHandler<BillingPreferencesForm> = (data) => {
    const request = mapBillingPreferencesFormToRequest(data);
    updateBillingPreferences({
      applicationId: application.id,
      data: request
    }, {
      onError: handleError
    });
  };

  if (updateBillingPreferencesSuccess) {
    return <Navigate to={getStepRoute(WIZARD_STEP.CONFIRM) } />;
  }

  return (
    <WorkableApplicationStepGuard application={application} >
      <Grid2 container spacing={4}>
        <Grid2 xs={12}>
          <Typography variant="h1" gutterBottom>Fees to Connect Service(s)</Typography>
          {serviceAddress ? <div>
            <Typography marginTop={4} variant="h2" gutterBottom>Service Location</Typography>
            <Typography gutterBottom>
              <AddressView address={serviceAddress}/>
            </Typography>
          </div>: <></> }
          <Box marginTop={4} gap={4}>
            <ServiceAvailabilityAlert services={services} />
            <Alert severity="info">
              <span>
                A credit review has been completed and we’ve checked system information. Please review Fee information, make selection (if applicable), and select Next to proceed.
              </span>
            </Alert>
          </Box>
          <StyledTable size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ borderBottom: 'none', paddingBottom: 0, paddingTop: 0, verticalAlign: 'bottom' }}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="h2">
                      Fees
                    </Typography>
                    <Typography variant="caption">
                      &nbsp;
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ textAlign: 'right', borderBottom: 'none', paddingBottom: 0, paddingTop: 0, verticalAlign: 'bottom' }}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="h2">
                      Billing Options
                    </Typography>
                    <Typography variant="caption">
                      *see explanation below
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ paddingTop: 0 }}>Description</TableCell>
                <TableCell sx={{ textAlign: 'right', paddingTop: 0 }}>Standard Amount</TableCell>
                {isPrepaidAvailable ? <TableCell sx={{ textAlign: 'right', paddingTop: 0 }}>Prepaid Amount</TableCell> : <></>}
              </TableRow>
            </TableHead>
            <TableBody>
              {fees.map(fee => (
                <TableRow key={fee.id}>
                  <FeeDescriptionCell
                    description={fee.description}
                    feeDescriptionRef={FEE_DESCRIPTION_REF}
                    isApplicable={fee.isApplicable}
                    feeType={fee.type}
                  />
                  <FeeAmountCell feeAmount={fee.standardAmount} isApplicable={fee.isApplicable} />
                  {isPrepaidAvailable ? <FeeAmountCell feeAmount={fee.prepaidAmount} isApplicable={fee.isApplicable} /> : <></>}
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell scope="col">Total</TableCell>
                <TableCell style={{ fontWeight: '1200', textAlign: 'right' }} scope="col">{formatCurrency(standardTotal) ?? <>&mdash;</>}</TableCell>
                {isPrepaidAvailable ? <TableCell style={{ textAlign: 'right' }} scope="col">{formatCurrency(prepaidTotal) ?? <>&mdash;</>}</TableCell> : <></>}
              </TableRow>
            </TableFooter>
          </StyledTable>
        </Grid2>
        <Grid2 container spacing={2} xs={12}>
          <Grid2 component="article" xs={12}>
            <Typography variant="caption" id={membershipDescriptionId} display="block">
              <sup>1</sup> One Membership is required for service(s). If an active Membership exists, no additional Membership will be charged.
            </Typography>
          </Grid2>
        </Grid2>
        <Grid2
          component={'form'}
          alignItems="start"
          flexDirection="column"
          justifyItems="flex-start"
          xs={12}
          md={6}
          container
          spacing={2}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid2 component="fieldset" xs={12} style={{ border: 'none' }}>
            <Typography variant="h2" component="legend" padding={0}>
              Select Billing Option
            </Typography>
            {availableServices.map((service, index) => (
              <Grid2 key={service.id} xs={12}>
                <Controller
                  control={control}
                  name={`services.${index}.id`}
                  defaultValue={service.id}
                  render={({ field }) => (
                    <input type="hidden" {...field} />
                  )}
                />
                <FormControl>
                  <FormLabel id={`${fieldIdPrefix}-${service.id}-preference`}>{service.description}</FormLabel>
                  {service.isPreferenceEditable ? (
                    <Controller
                      control={control}
                      name={`services.${index}.billingPreference`}
                      defaultValue={service.billingPreference}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          aria-labelledby={`${fieldIdPrefix}-${service.id}-preference`}
                          {...field}
                        >
                          <FormControlLabel control={<Radio/>} label="Standard" value={BillingPreference.STANDARD}/>
                          <FormControlLabel control={<Radio/>} label="Prepaid" value={BillingPreference.PREPAID}/>
                        </RadioGroup>
                      )}
                    />
                  ) : (
                    <>
                      <Typography variant="body1">{formatBillingPreference(service.billingPreference)}</Typography>
                      <Controller
                        control={control}
                        name={`services.${index}.billingPreference`}
                        defaultValue={service.billingPreference}
                        render={({ field }) => (
                          <input type="hidden"{...field} />
                        )}
                      />
                    </>
                  )}
                </FormControl>
              </Grid2>
            ))}
          </Grid2>
          <Grid2 xs={12}>
            <SubmitButton isSubmitting={updateBillingPreferencesIsPending}>Next</SubmitButton>
          </Grid2>
        </Grid2>
        <Grid2 spacing={2} container padding={0} xs={12} md={6}>
          <Grid2 component="article" xs={12}>
            <Typography variant="body1" gutterBottom>Standard Billing</Typography>
            <Typography component="ul" variant="caption" style={{ margin: 0, fontSize: '0.85rem' }}>
              <li>Bill calculated each month from meter read</li>
              <li>Deposit, if applicable</li>
              <li>Available for electric, water, and wastewater</li>
            </Typography>
          </Grid2>
          {isPrepaidAvailable ?
            <Grid2 component="article" xs={12}>
              <Typography variant="body1">
                Prepaid Billing
              </Typography>
              <Typography
                component="ul"
                variant="caption"
                style={{ margin: 0, fontSize: '0.85rem' }}
              >
                <li>Pay as you go and maintain a credit balance daily</li>
                <li>No late fees or disconnect fees</li>
                <li>{formatCurrency(prepaidMinimumToStart)} minimum prepaid credit required at sign up</li>
                <li>
                  If credit balance reaches zero, {formatCurrency(prepaidMinimumToStart)} minimum prepaid credit required
                </li>
                <li>Lower deposit than Standard Billing</li>
                <li>Bill and notifications sent by email</li>
                <li>Available for electric only</li>
              </Typography>
            </Grid2> : <></>}
        </Grid2>
      </Grid2>
    </WorkableApplicationStepGuard>
  );
};

const StyledTable = styled(Table)`
  width: 100%;
  table-layout: fixed;

  @media (min-width: 600px) {
    table-layout: auto;
  }
`;

type BillingPreferencesStepContainerProps = object & WithApplicationFromRouteProps;

const BillingPreferencesPage = WithApplicationFromRoute(BillingPreferencesStepContainer);

export default BillingPreferencesPage;
